import { InputText } from 'primereact/inputtext'
import React from 'react'
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from 'react';
import Footer from './Footer';
function Contact() {
   const [value, setValue] = useState('');
   return (
      <div className='h-screen overflow-auto '>
         <div className="col-12 text-center  ">
            {/* <img src="./cs_logo_latest.png" alt="sample" height="50" className="mb-3" /> */}
            <div className="text-900 text-3xl font-bold ">Contact Us</div>
            <div className='csunderline'></div>
         </div>
         <div className="col-12 surface-card shadow-3 mx-3 py-3 flex flex-wrap justify-content-between">
            <div className="col-12 lg:col-6 px-8 border-round ">
               <InputText type="text" className="p-inputtext-lg w-full mb-5" placeholder="Name*" />
               <InputText type="text" className="p-inputtext-lg w-full mb-5" placeholder="Email*" />
               <InputText type="text" className="p-inputtext-lg w-full mb-5" placeholder="Company*" />
               <InputTextarea autoResize value={value} className='mb-3 w-full' placeholder="Subject*" onChange={(e) => setValue(e.target.value)} rows={5} cols={84} />
               <button aria-label="Send Message" className="p-button p-component w-auto p-2 text-white "><span className="p-button-icon p-c p-button-icon-left pi pi-send"></span><span className="p-button-label p-c ">Send Message</span><span role="presentation" className="p-ink" ></span></button>
            </div>

            <div className="col-12 lg:col-6" >
               <div class="block font-bold p-1 border-round "> <iframe className='w-9 h-15rem' frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=SunMars%20Residency,%20nizampet+(Chinmmai%20Systems)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population calculator map</a></iframe></div>
               <div class="block font-bold p-1 border-round ">
                  <h3 className="flex align-items-center "><i className="pi pi-phone mr-3"></i><span>+91-9885016432</span></h3></div>
               <div class="block font-bold p-1 border-round ">
                  <h3 className="flex align-items-center "><i className="pi pi-inbox mr-3"></i><span>ask@chinmmaisys.com</span></h3></div>
               <div class="block font-bold p-1 border-round ">
                  <h3 className="flex align-items-center"><i className="pi pi-directions mr-3"></i><span>Hyderabad, Telangana, India.</span></h3></div>
            </div>
         </div>
         <div className="h-12rem align-items-center bg-primary-light px-8  align-items-center text-white  ">
            <div className="flex flex-column sm:flex-row align-items-center justify-content-center lg:py-1  ">
               <div className="flex mt-1 sm:mt-0 align-items-center pr-8"><img src="./website/cs-small.png" alt="Image" height="40" /><span className='font-bold text-xl'>CHINMMAI SYSTEMSS</span></div>

               <div className="flex flex-nowrap font-medium pl-8">
                  <p>Copyright &copy; 2023, Chinmmai Systemss Private Limited. All Rights Reserved.</p>
               </div>
            </div>
         </div>

      </div>
   )
}

export default Contact