export const PhotoService = {
    getPhotosData() {
        return [
            {
                id: '1000',
                code: 'Enterprise Automation',
                name: 'Agro Chemicals',
                points: 'HR Management | Procurement Management | Sales & Dispatch | Goods Receipt ',
                image: './website/Homepage_Banner1.png',
                description : 'Connecting all departments in a seamless manner, providing integrated data view.'
              
            },
            {
                id: '1001',
                code: 'Enterprise Automation',
                name: 'Real Estate',
                points: 'HR Management | Procurement Management | Sales & Dispatch | Goods Receipt ',
                image: './website/Homepage_Banner2.png',
                description : 'Automation across all functional areas in construction industry'
            },
        
            {
                id: '1003',
                code: 'Dispatch | Easy Dispatch',
                name: 'Manufacturing ',
                points: 'HR Management | Procurement Management | Sales & Dispatch | Goods Receipt ',
                image: './website/Homepage_Banner3.png',
                description : 'Tracking of all deliverables from Order to Dispatch'
            },
           
        ];
    },
   
    

    getPhotos() {
        return Promise.resolve(this.getPhotosData());
    },

 
};
