import React from "react";

const initialState = {
        key           : 'vn1',
        id            : 1,
        ProductName   : 'Chinmmai Systemss',
        HeaderTitle   : 'Vidyodaya',
        SubTitle1     : 'Experience Future of',
        SubTitle2     : 'App Development',
        Image         :  "./image.png",
        PhoneNumber   : '+91-9885016432',
        Email         : 'sample@sample.com',

        // MenuItems
        Menu1         : 'About Us',
        Menu3         : 'Solutions',
        Menu4         : 'Contact',
        Menu5         : 'Resource Center',
        Menu6         : 'Company',
        Menu7         : 'Country/Region',
        Menu8         : 'Support',
        Menu9         : 'Contact Us',
        
        
        
      
        
        
        
         
        
        


  // FooterMenu
        FooterMenu1   :   'About Us',
        FooterMenu2   :   'News',
        FooterMenu3   :   'Events',
        FooterMenu4   :   'Careers',
        FooterMenu5   :   'Downloads'






};

export const DataContext = React.createContext();

export const DataContextProvider = ({ children }) => {
  return (
    <DataContext.Provider value={initialState}>{children}</DataContext.Provider>
  );
};
