import { useContext } from 'react';
import React from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { DataContext } from './context/DataContext';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';

function Topbar() {



    const items = [
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'Delete', icon: 'pi pi-fw pi-trash' },
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'New', icon: 'pi pi-fw pi-plus' },
    ]

    const ProductData = useContext(DataContext);
    const { ProductName, PhoneNumber } = ProductData;
    const [visible, setVisible] = useState(false);
    const [urlToOpen, setUrlToOpen] = useState('');
    const handleButtonClick = () => {
        setUrlToOpen('https://www.chinmmaisystems.com/');
    }
    const topbarbuttons = (
        <div className='flex p-2'>
            {/* <Button label={PhoneNumber} type="button" className=' transparent ' /> */}
            {/* <Button label="Book Free Demo" icon="" className='w-8rem bg-teal-900 text-white' onClick={() => setVisible(true)} /> */}
            <Dialog header="Book Free Demo" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>

                <div className="col-12">
                    <form id="contact" action="" method="post">
                        <h3>Book Free Demo</h3>
                        <h4>Contact us for custom quote</h4>
                        <fieldset>
                            <InputText placeholder="Your name" type="text" tabIndex="1" required autofocus />
                        </fieldset>
                        <fieldset>
                            <InputText placeholder="Your Email Address" type="email" tabIndex="2" required />
                        </fieldset>
                        <fieldset>
                            <InputText placeholder="Your Phone Number (optional)" type="tel" tabIndex="3" required />
                        </fieldset>
                        <fieldset>
                            <InputText placeholder="Your Web Site (optional)" type="url" tabIndex="4" required />
                        </fieldset>
                        <fieldset>
                            <textarea placeholder="Type your message here...." tabIndex="5" required></textarea>
                        </fieldset>
                        <fieldset>
                            <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                        </fieldset>
                        {/* <p className="copyright">Designed by <a href="www.chinmmaisystems.com" target="_blank" title="Chinmmai Systems">Chinmmai Systemss</a></p> */}
                    </form>
                </div>
            </Dialog>
            <Button label="Login" type="button" icon="pi pi-user" className='w-5rem text-white border-round-lg border-3 border-primary-light' onClick={() => setUrlToOpen} />
        </div>
    )
    return (
        <div >
            <div className='col-12 flex align-content-center flex-wrap justify-content-between bg-white'>
                <Link href="/" className="flex align-items-center">
                    <img src='./website/Chinmmaisystemss.png' alt="Sakai Logo" height="50" className="mr-0 lg:mr-2" />
                    {/* <span className="text-900 font-medium text-2xl line-height-3 mr-8">Chinmmai Systemss</span> */}
                </Link>
                {/* <div className="flex flex-wrap justify-content-start ">
                    <img src="./website/Chinmmaisystemss.png" className="mx-3 w-full" alt="logo" height="50" />
                </div> */}
                <div className="layout-topbar-icons ">
                    {topbarbuttons}
                </div>
            </div>
        </div>
    )
}

export default Topbar