import React from 'react';
import App from './app';
import { DataContextProvider } from './context/DataContext';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
createRoot(document.getElementById('root')).
  render(
    <DataContextProvider>
      <App />
      </DataContextProvider>
    )