import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { PhotoService, ProductService } from './PhotoService';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import Footer from './Footer';

export default function NewSlider() {
  const [images, setImages] = useState(null);

  useEffect(() => {
    PhotoService.getPhotos().then((data) => setImages(data.slice(0, 3)));
  }, []);
  const responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  const itemTemplate = (item) => {
    return <img src={item.image} alt={item.description} style={{ width: '100%', display: 'block' }} />;
  };

  return (
    <div className='h-screen overflow-scroll '>
      <div className=''>
            <Carousel value={images} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel w-full border-bottom-3 border-primary-light " circular
        autoplayInterval={3000} itemTemplate={itemTemplate} showNavigators={false} showIndicators={false} />
      </div>
      <div className=" text-center flex flex-column py-6">
        <h2 className="text-900 font-bold text-2xl">CS - Dynamo</h2>
        <div className='csunderline'></div>
        <div className='grid'>
          <div className='col-12 md:col-7 px-8 '>
            <div className='flex flex-column justify-content-center flex-wrap text-sm' style={{ maxWidth: '732px', alignSelf: 'center' }}>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Discover the next frontier in enterprise solution development with "CS Dynamo", our cutting-edge No Code Platform. Say goodbye to traditional coding hassles and embrace a new era of agility. "CS Dynamo" empowers you to create robust, tailored applications with an intuitive drag-and-drop interface.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Revolutionize your workflow by rapidly developing applications suited to your unique business needs. With "CS Dynamo", innovation is at your fingertips, no coding expertise required. Seamlessly integrate diverse functionalities, streamline processes, and enhance productivity across your organization.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                "CS Dynamo" is designed for speed and efficiency, allowing you to go from concept to deployment in record time. Leverage our platform to build scalable solutions that evolve with your business. Collaborate effortlessly with cross-functional teams, breaking down silos and fostering a culture of innovation.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Stay ahead of the curve with 'CS Dynamo's real-time collaboration features, enabling multiple contributors to work concurrently on projects. Our platform ensures that your enterprise solutions are always up-to-date, agile, and ready to adapt to changing business landscapes.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                "CS Dynamo" isn't just a No Code Platform; it's a catalyst for digital transformation. Whether you're developing CRM systems, project management tools, or data analytics dashboards, "CS Dynamo" empowers you to turn ideas into reality swiftly and seamlessly.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Join the ranks of forward-thinking enterprises that have embraced the power of "CS Dynamo". Transform your business processes, reduce development cycles, and unlock unparalleled agility. Experience the future of enterprise solution creation-welcome to "CS Dynamo."</p>
            </div>
          </div>
          <div className='col-12 md:col-5 sm:col-12 flex align-content-center flex-wrap justify-content-center  '>
            <img className='' src='./website/controls.png' height={300} />
          </div>
        </div>
      </div>

      <div class="surface-ground py-5 flex flex-wrap ">
        <div className='col-12 lg:col-5 md:col-4 text-5xl text-center'><span className=''>Industry Solutions</span>
          <span className=''><img className='pt-3' src='./website/IndustrySolutionsBanner.png' /></span>
        </div>
        <div class="col-12 lg:col-7 md:col-8 flex flex-wrap p-3 gap-3  ">
          <div class="col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3 ">
            <div class="mb-4 ">
              <img src="./website/Industry1.png" width="90" height="80" class="-mt-6 " alt="Industry" /><span class="block text-900 mb-1 text-xl font-bold">Manufacturing</span>
              <p class="text-600 m-0 font-medium">"Transform manufacturing with our cutting-edge software: real-time monitoring, efficiency, precision, scalability, and seamless integration for a transformative production experience with data-driven decision support."</p>
            </div>
          </div>

          <div class="col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3">
            <div class="mb-4">
              <img src="./website/Education.png" width="80" height="80" class="-mt-6" alt="Education" /><span class="block text-900 mb-1 text-xl font-bold">Education</span>
              <p class="text-600 m-0 font-medium">"Transform education management with our integrated software solution. From enrollment to graduation, streamline processes, enhance communication, and empower educational institutions for a seamless and efficient experience."</p>
            </div>
          </div>

          <div class=" col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3   ">
            <div class="mb-4">
              <img src="./website/Retail1.png" width="80" height="80" class="-mt-6" alt="Retail" /><span class="block text-900 mb-1 text-xl font-bold">Retail</span>
              <p class="text-600 m-0 font-medium">"Elevate retail management with our integrated software. From inventory to sales, enhance efficiency, optimize customer engagement, and boost profitability with our seamless and intuitive solution."</p>
            </div>
          </div>

          <div class="col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3  ">
            <div class="mb-4">
              <img src="./website/RealEstate.png" width="80" height="80" class="-mt-6" alt="RealEstate" /><span class="block text-900 mb-1 text-xl font-bold">Reality</span>
              <p class="text-600 m-0 font-medium">"Optimize your real estate business with our integrated software, promoting seamless collaboration across the project lifecycle. Enhance efficiency, streamline communication, and ensure success from planningto completion."</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}