import React, { useState, useEffect } from 'react';
import { ProductService } from './ProductService';
import { DataView } from 'primereact/dataview';
import 'primeicons/primeicons.css';
import Footer from './Footer';
import { Divider } from 'primereact/divider';

export default function Dataview() {

    var mSolutionsData = [
        {
            id: '1000',
            code: '"CS Agronex": From Lab to Field your Agrochemical Partner',
            name: 'Agrochemicals',
            // points: 'HR Management | Procurement Management | Sales & Dispatch | Goods Receipt  | Inventory Management | Production | Finance  ',
            image: './website/agro.png',
            // description: 'Connecting all departments in a seamless manner, providing integrated data view.',
            // point1: 'CS- Agronex: From Lab to Field your Agrochemical Partner',
            point2: 'Elevate your business with "CS Agronex" exclusively tailored for Agrochemical industry.',
            point3: 'Seamlessly integrate operations, gain real time insights, incorporate advanced costing and analytics and achieve precision.',
            point4: 'Optimize costs, ensure compliance and make informed decisions. ',
            point5: 'Ready to transform your business ?',
            point6: 'Contact us for a personalized demonstration and propel your company towards sustainable growth.'
        },
        {
            id: '1001',
            code: '"CS BuildNex Core": Redefining Foundations, Redefining Future',
            name: 'Real Estate',
            // points: 'HR Management | Project Management | Material Management | Marketing & Sales | Finance ',
            image: './website/real-estate-2.png',
            // description: 'Automation across all functional areas in construction industry',
            // point1: 'CS BuildNex Core: Redefining Foundations, Redefining Future',
            point2: 'Our Reality Software offers a transformative approach to your construction projects that streamline project initiation, enhance financial transparency, optimize procurement, seamlessly integrate lead management, sales, finance, and construction modules and achieve substantial cost savings. Our end to end construction management ensures efficient scheduling, resource allocation, and collaboration, positioning your company for success.',
            point5: 'Ready to revolutionize your projects and maximize your bottom line ?',
            point6: 'Contact us for a personalized demonstration and experience the cost saving value proposition.'

        },

        {
            id: '1003',
            code: '"CS UnityPlex": From Production to Delivery Connect Every Dot.',
            name: 'Manufacturing ',
            // points: 'HR Management | Job Order Management | Dispatch Management | Packaging using Mobile App  |  Labels Design & Generation(QR Code based) ',
            image: './website/industry.png',
            // description: 'Tracking of all deliverables from Order to Dispatch',
            // point1: 'CS UnityPlex: From Production to Delivery UnityPlex Connects Every Dot.',
            point2: 'Experience the all encompassing efficiency of "CS UnityPlex", where production, packaging, dispatch, and procurement seamlessly converge. Our platform integrates personalized production planning, on the go packing, efficient dispatch and procurement management, all accessible through dedicated mobile apps. Stay connected at every stage, empowering your teams to adapt on the fly and ensuring a unified approach to tasks. Elevate customer satisfaction with real time order updates and transparent communication.',
            point6: 'Choose "CS UnityPlex" for a holistic, mobile driven solution that brings excellence to every facet of your operations.',

        },
        {
            id: '1004',
            code: '"CS RetailPro Connect": Shopping Simplified, Results Amplified',
            name: 'Retail Stores',
            // points: 'HR Management | Kits Packaging & Dispatch | Kit Sales | Finance | Procurement Management | Inventory Management | Goods Receipt ',
            image: './website/Retail.png',
            // description: 'Enabling seamless end to end data flow from Purchase Indent to Invoice generation',
            // point1: 'CS RetailPro Connect: Shopping Simplified, Results Amplified',
            point2: 'Welcome to "CS RetailPro Connect", where retail management meets seamless efficiency. Our software combines an intuitive Point of Sale system, advanced inventory management, customer centric CRM, smart sales analytics and an efficient procurement and finance modules. Unleash the power of unified multi channel integration, enabling you to streamline operations and drive growth.',
            point3: 'Whether you are a boutique or a large retail chain, RetailPro Connect is your key to efficient and successful retail management.',
            point6: 'Contact us for a personalized demonstration and embark on a journey of retail excellence.'
        },
        {
            id: '1005',
            code: '"CS Vidyodaya": Shaping Futures, Streamlining Operations',
            name: 'Education',
            // points: 'Admissions | Student Administration | Finance | SecureTrak | Academics | HR Management | Logins',
            image: './website/college.png',
            // description: 'Integrated process solution involving Academician, Student, Parent, Admin.',
            // point1: '"CS Vidyodaya": Shaping Futures, Streamlining Operations',
            point2: 'Experience the seamless scalability of "CS - Vidyodaya" institute management system. ',
            point3: 'Our solution not only manages every aspect of your institution but also facilitates effortless expansion to multiple branches. From admissions to alumni relations, finance, online classrooms, and student transport, our system ensures efficiency and growth across your educational network by streamlining operations, enhancing financial transparency, and ensuring efficient learning environment.',
            point5: 'Discover the transformative power of CS Vidyodaya ?',
            point6: 'Contact us for a personalized demonstration and witness the ease of scaling your institution.'

        },

    ];

    const CSSolutions = (product) => {

        return (
            <div className='border-bottom-2 border-primary-light lg:flex flex-wrap align-items-center'>
                <div className='col-12 md:col-8 lg:col-7 py-4 '>
                    <div className="text-black text-4xl font-semibold mb-2 pl-4 font-normal">{product.name}</div>
                    <div className="font-normal text-xl mb-2 font-semibold pl-4">{product.code}</div>
                    <div className="font-normal text-base pl-4 mb-2">{product.point1}</div>
                    <div className="font-normal text-base pl-4 ">{product.point2}</div>
                    <div className="font-normal text-base pl-4 ">{product.point3}</div>
                    <div className="font-normal text-base pl-4 mb-2">{product.point4}</div>
                    <div className="font-normal text-base pl-4  pt-3">{product.point5}</div>
                    <div className="font-normal text-base pl-4 mb-2">{product.point6}</div>
                    {/* <div className="text-base mb-2 pl-4">{product.description}</div> */}
                    <div className="font-normal text-base pl-4 mb-2">{product.points}</div>
                    <a href='#' className="inline-flex align-items-right bg-primary text-primary border-round-2xl font-bold ml-4 p-2 mr-8 mt-4">More details</a>
                </div>
                <div className="col-12 md:col-4 lg:col-5 ">
                    <img src={product.image} className='product-image float-right w-full' alt={product.name} />
                </div>
            </div>
        );
    };

    // Out of the box solutions
    return (
        <div className='col-12 p-0 m-0 h-screen overflow-scroll'>
                <img src="/Dataview_Banner1.png" alt="Solutions" className="col-12 p-0 m-0 md:w-full border-bottom-2 border-primary-light sm:flex flex-wrap justify-content-center" />
            <div className='p-2'>
                <DataView value={mSolutionsData} itemTemplate={CSSolutions} />
            </div>
            <Footer />
        </div>
    )
}