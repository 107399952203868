import React from 'react'
import { useState } from 'react';
function CSSlider() {
    

    return (
        <div className="block-content">
        <div className="ng-star-inserted">
           <div className="surface-ground text-center px-4 py-8 md:px-6 lg:px-8">
              <div className="flex flex-column md:flex-row">
                 <div className="flex flex-grow-1 mb-4 md:mb-0 md:px-4 py-4 md:py-0">
                    <div className="shadow-2 surface-card border-round relative">
                       <img src="./website/review.png" width="100" height="100" className="absolute left-50" style={{ marginLeft: '-50px', top: '-50px' }} />
                       <div className="px-4 pb-4 pt-8 relative">
                          <p className="text-900 font-italic line-height-3 text-lg m-0 pb-4 border-bottom-1 surface-border"> “Lorem ipsum dolor sit amet, sed tempor incididunt ut labore et dolore magna alique.” </p>
                          <div className="text-900 font-semibold line-height-3 mt-4">Name</div>
                          <div className="text-600 line-height-3 text-sm">Company Title</div>
                       </div>
                    </div>
                 </div>
                 <div className="flex flex-grow-1 mb-4 md:mb-0 md:px-4 py-4 md:py-0">
                    <div className="shadow-2 surface-card border-round relative">
                       <img src="./website/review.png" width="100" height="100" className="absolute left-50" style={{ marginLeft: '-50px', top: '-50px' }} />
                       <div className="px-4 pb-4 pt-8 relative">
                          <p className="text-900 font-italic line-height-3 text-lg m-0 pb-4 border-bottom-1 surface-border"> “Lorem ipsum dolor sit amet, sed tempor incididunt ut labore et dolore magna alique.”</p>
                          <div className="text-900 font-semibold line-height-3 mt-4">Name</div>
                          <div className="text-600 line-height-3 text-sm">Company Title</div>
                       </div>
                    </div>
                 </div>
                 <div className="flex flex-grow-1 md:px-4 py-4 md:py-0">
                    <div className="shadow-2 surface-card border-round relative">
                       <img src="./website/review.png" width="100" height="100" className="absolute left-50" style={{ marginLeft: '-50px', top: '-50px' }} />
                       <div className="px-4 pb-4 pt-8 relative">
                          <p className="text-900 font-italic line-height-3 text-lg m-0 pb-4 border-bottom-1 surface-border"> “Lorem ipsum dolor sit amet, sed tempor incididunt ut labore et dolore magna alique.”</p>
                          <div className="text-900 font-semibold line-height-3 mt-4">Name</div>
                          <div className="text-600 line-height-3 text-sm">Company Title</div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
    )
}

export default CSSlider